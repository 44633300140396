import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Btf_cb from "src/components/btf_cb"
import {isIeEdge,isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"
import Trbm from "@tightrope/trbm"
import Ctadisclosurecb2 from "src/components/ctadisclosurecb2"

const btfData = import("./data/data.json");
const trbmData = import("./data/trbmData.json");

const expressBrowsers = ['chrome','ie','edge','firefox','other'];

export default function spcEzBm() {

  return(
    <HomepageLayout>
    <div>
      <Helmet>
      <style type="text/css">{`

          html {
            scroll-behavior: smooth;
          }

        .footerlinkssingle-module--br a {
          display: none;
        }

        .footerlinkssingle-module--br {
          display: none;
        }

        .trbm-module--trbm button {
          border: none;
           font-size: 35px;
           font-weight: bold;
           border-radius: 8px;
           margin: 0px auto 5px;
           cursor: pointer;
           color: white;
           padding: 20px!important;
           width: 330px;
           background: #00c506;
           transition: .2s ease;
        }

        .trbm-module--trbm .trbm-module--steps {
          display: none;
        }

        .trbm-module--trbm .trbm-module--bullets li {
          font-weight: normal;
          font-size: 12pt;
          color: #333;
          padding-bottom: 10px;
        }

        .trbm-module--trbm .trbm-module--bullets {
          width: 74%;
          line-height: 175%;
        }

        .trbm-module--trbm h2 {
          font-weight: bold;
           font-size: 18px;
           margin: 10px 0px 25px;
           padding-bottom: 0px;
        }

        .trbm-module--trbm h1 {
          margin: 10px auto;
          width: auto;
          font-weight: bold;
        }

        .trbm-module--trbm .trbm-module--bmlpwrap {
          background-size: cover;
          font-family: 'Roboto', sans-serif;
          font-weight: normal;
          background: url(/img/convertorfull-dark.jpg);
          height: 100vh;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .trbm-module--trbm .trbm-module--card {
          margin: 0;
          padding: 20px 30px;
        }
        .trbm-module--trbm .trbm-module--logo {
          width: 175px;
        }

        .footerlinks-module--br {
          display: none!important;
        }

        .cbdisclosureint-module--cbdisclaimerShort {
          color: #f1f1f1;
        }

        `}
        </style>

        <title>Edit PDFS for Free! - frompdftodoc.com</title>
      </Helmet>

      <Trbm data={trbmData} browsers={expressBrowsers}><Ctadisclosurecb2></Ctadisclosurecb2></Trbm>
      <Btf_cb data={btfData} browsers={expressBrowsers}></Btf_cb>
    </div>



    </HomepageLayout>
  )
}
